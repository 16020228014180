// src/utils/axiosInstance.js

import axios from 'axios';

// Create an Axios instance with your base URL and instruct it to send cookies.
const axiosInstance = axios.create({
  baseURL: 'https://synchrotronai.com/api',
  withCredentials: true,
});

// Add a response interceptor to handle errors.
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Check if the error response status is 401 (Unauthorized)
    if (error.response && error.response.status === 401) {
      // Get the request URL (this should be relative because of the baseURL)
      const requestUrl = error.config.url;
      
      // If the request is not going to an auth-related endpoint, redirect to login.
      // This prevents redirect loops for auth endpoints (like login, signup, etc.)
      if (!requestUrl.startsWith('/auth/')) {
        // Optionally, you might want to clear local storage or cookies here.
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
