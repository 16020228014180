// src/components/Footer/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FaTwitter, FaLinkedin, FaGithub } from 'react-icons/fa';
import styles from './Footer.module.css';

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerMainBox}>
        <div className={styles.footerSections}>
          <div className={styles.footerColumn}>
            <h4>Our Research</h4>
            <ul>
              <li><Link to="/research">Overview</Link></li>
              <li><Link to="/research/index">Index</Link></li>
            </ul>
            <h4>Latest Advancements</h4>
            <ul>
              <li><Link to="/research/autonomous-systems">Autonomous Systems</Link></li>
            </ul>
          </div>
          
          <div className={styles.footerColumn}>
            <h4>Solo</h4>
            <ul>
              <li><Link to="/products/solo/for-anyone">For Anyone</Link></li>
              <li><Link to="/products/solo/for-enterprise">For Enterprises</Link></li>
              <li><Link to="/products/solo">Learn More</Link></li>
            </ul>
          </div>

          <div className={styles.footerColumn}>
            <h4>Company</h4>
            <ul>
              <li><Link to="/about-us">About Us</Link></li>
              <li><Link to="/news">News</Link></li>
              <li><Link to="/careers">Careers</Link></li>
            </ul>
          </div>

          <div className={styles.footerColumn}>
            <h4>Terms & Policies</h4>
            <ul>
              <li><Link to="/terms-of-use">Terms of Use</Link></li>
              <li><Link to="/privacy-policy">Privacy Policy</Link></li>
              <li><Link to="/brand-guidelines">Brand Guidelines</Link></li>
              <li><Link to="/policies">Other Policies</Link></li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.footerBottomBox}>
        <p>&copy; 2024-2025 SynchrotronAI. All rights reserved.</p>
        <div className={styles.socialIcons}>
          <a 
            href="https://twitter.com/synchrotronai" 
            target="_blank" 
            rel="noopener noreferrer"
            aria-label="Twitter"
          >
            <FaTwitter className={styles.socialIcon} />
          </a>
          <a 
            href="https://linkedin.com/company/synchrotronai" 
            target="_blank" 
            rel="noopener noreferrer"
            aria-label="LinkedIn"
          >
            <FaLinkedin className={styles.socialIcon} />
          </a>
          <a 
            href="https://github.com/synchrotronai" 
            target="_blank" 
            rel="noopener noreferrer"
            aria-label="GitHub"
          >
            <FaGithub className={styles.socialIcon} />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;