// src/components/Dashboard/ChatBox/ChatBox.js

import React, { useState, useContext, useCallback, useRef } from 'react';
import { FaPaperPlane, FaPaperclip } from 'react-icons/fa';
import debounce from 'lodash.debounce';
import styles from './ChatBox.module.css';
import { MessageContext } from '../../../../contexts/MessageContext';
import { WebSocketContext } from '../../../../contexts/WebSocketContext';
import axiosInstance from '../../../../utils/axiosInstance';

const ChatBox = () => {
  const { addMessage, activeConversationId, setActiveConversationId } = useContext(MessageContext);
  const { isConnected, sendMessage } = useContext(WebSocketContext);
  const [input, setInput] = useState('');
  const [isLocked, setIsLocked] = useState(false);
  const [error, setError] = useState(null);
  const isMountedRef = useRef(true);

  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const handleSend = useCallback(async () => {
    if (!input.trim() || isLocked || !isMountedRef.current) return;
  
    setIsLocked(true);
    setError(null);
    const messageText = input.trim();
  
    try {
      let conversationId = activeConversationId;
      if (!conversationId) {
        console.log('Creating new conversation...');
        const response = await axiosInstance.post('/conversations');
        
        if (!response.data?.conversation?._id) {
          throw new Error('Failed to create conversation');
        }
        
        conversationId = response.data.conversation._id;
        if (isMountedRef.current) {
          setActiveConversationId(conversationId);
        }
      }
      
      // Use MongoDB-style ID format
      const messageId = `${conversationId}-msg-${Date.now()}`;
      
      const messagePayload = {
        id: messageId,
        conversation_id: conversationId, // snake_case
        sender: 'user',
        text: messageText,
        timestamp: new Date().toISOString(),
        type: 'MESSAGE_TYPE_USER_MESSAGE'
      };
      
      addMessage(conversationId, messagePayload);
  
      const wasSent = sendMessage({
        message_text: messageText,
        parent_id: messageId
      });

      if (!wasSent) {
        console.log('Message is queued and will be sent when connected.');
      }

      if (isMountedRef.current) {
        setInput('');
      }
  
    } catch (error) {
      console.error('Error sending message:', error);
      
      if (isMountedRef.current) {
        const errorMessage = error.response?.data?.error || error.response?.data?.message || error.message || 'Failed to send message';
        setError(errorMessage);
        
        if (!errorMessage.toLowerCase().includes('connection')) {
          setInput('');
        }
      }
    } finally {
      if (isMountedRef.current) {
        setIsLocked(false);
      }
    }
  }, [
    input,
    isLocked,
    activeConversationId,
    sendMessage,
    addMessage,
    setActiveConversationId
  ]);

  const debouncedHandleSend = React.useMemo(
    () => debounce(handleSend, 300),
    [handleSend]
  );

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter' && !e.shiftKey && !isLocked) {
        e.preventDefault();
        debouncedHandleSend();
      }
    },
    [debouncedHandleSend, isLocked]
  );

  const handleInput = useCallback((e) => {
    const textarea = e.target;
    textarea.style.height = 'auto';
    textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
    setInput(e.target.value);
    
    if (error) setError(null);
  }, [error]);

  React.useEffect(() => {
    return () => {
      debouncedHandleSend.cancel();
    };
  }, [debouncedHandleSend]);

  return (
    <div className={styles.chatboxContainer}>
      <div className={styles.chatboxInputArea}>
        <button
          className={styles.chatboxAttachmentButton}
          onClick={() => alert('File attachment is not implemented yet.')}
          aria-label="Attach file"
          disabled={isLocked}
        >
          <FaPaperclip />
        </button>

        <textarea
          className={styles.chatboxTextarea}
          placeholder={isLocked ? "Sending..." : "Type your message..."}
          value={input}
          onChange={handleInput}
          onKeyPress={handleKeyPress}
          disabled={isLocked}
          rows={1}
          aria-label="Message input"
        />

        <button
          className={`${styles.chatboxSubmitButton} ${(input.trim() === '' || isLocked) ? styles.disabled : ''}`}
          onClick={debouncedHandleSend}
          disabled={!input.trim() || isLocked}
          aria-label="Send message"
        >
          <FaPaperPlane />
        </button>
      </div>
      
      {error && (
        <div className={styles.chatboxError} role="alert">
          {error}
          <button 
            className={styles.retryButton}
            onClick={() => {
              setError(null);
              handleSend();
            }}
            disabled={isLocked}
          >
            Retry
          </button>
        </div>
      )}
    </div>
  );
};

export default ChatBox;
